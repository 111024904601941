import styled from 'styled-components';
import { Flex } from '@kiwicom/account-components';

const MediaWrapper = styled.div`
  width: 100%;
`;

const FlexWithRightMargin = styled(Flex)``;

export { MediaWrapper, FlexWithRightMargin };
