import styled, { css } from 'styled-components';
import { mediaQueries as media } from '@kiwicom/account-global-contexts';

type StepProps = {
  isPrimary?: boolean;
  isSecondary?: boolean;
  isDesktop?: boolean;
};

const Container = styled.ul`
  position: relative;
  border-radius: 12px;
  list-style: none;
  padding: 0;
  ${media.desktop(css`
    padding-bottom: ${({ theme }) => theme.orbit.space1000};
  `)}
`;

const Step = styled.li<StepProps>`
  position: relative;
  padding-bottom: 14px;
  ${media.desktop(css`
    padding-bottom: 4px;
  `)}
  ${({ isPrimary }) =>
    isPrimary &&
    css`
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 17px;
        width: 2px;
        /* number of pixels to touch next node in timeline */
        height: 46px;
        ${media.desktop(css`
          height: 37px;
        `)}
        background: ${({ theme }) => theme && theme.orbit.paletteProductNormal};
      }
    `};

  ${({ isSecondary }) =>
    isSecondary &&
    css`
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 16px;
        ${media.desktop(css`
          top: 16px;
        `)}
        width: 2px;
        /* number of pixels to touch next secondary (gray) node in timeline */
        height: 48px;
        ${media.desktop(css`
          height: 38px;
        `)}
        background: ${({ theme }) => theme && theme.orbit.paletteCloudDark};
      }
    `}
`;

const StepContent = styled.div`
  display: flex;
  p {
    min-height: 44px;
  }
  svg {
    margin-top: 3px;
    z-index: 10;
  }
`;

export { Container, Step, StepContent };
