import styled, { css } from 'styled-components';
import { mediaQueries as media } from '@kiwicom/account-global-contexts';

type ImageWrapperProps = {
  isWebview?: boolean;
};

const ButtonWrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.orbit.space600};
  width: 100%;

  button {
    margin-right: ${({ theme }) => theme.orbit.space600};
  }

  ${media.desktop(css`
    padding: 0;
    button {
      width: auto;
    }
  `)}
`;

const ImageWrapper = styled.div<ImageWrapperProps>`
  position: absolute;
  /* viewport width * image resolution ratio(257/375) */
  height: calc(100vw * 0.68);
  width: 100%;
  right: 0;

  top: ${({ isWebview }) => (isWebview ? '0' : 'var(--navbar-mobile-height)')};
  ${({ theme }) => theme.rtl && 'transform: rotateY(180deg)'};

  .imageDesktop {
    display: none !important;
  }

  ${media.desktop(css<ImageWrapperProps>`
    /* (viewport height - navbar height) * image resolution ratio (720/835) */
    width: calc((100vh - var(--navbar-desktop-height)) * 0.86);
    top: ${({ isWebview }) => (isWebview ? '0' : 'var(--navbar-desktop-height)')};
    height: calc(100vh - var(--navbar-desktop-height));

    @media only screen and (max-aspect-ratio: 7/5) {
      width: calc((100vh - var(--navbar-desktop-height)) * 0.62);
      .imageTablet {
        display: block !important;
      }
      .imageDesktop {
        display: none !important;
      }
    }
    /* (viewport height - navbar height) * image resolution ratio (523/835) */
    @media only screen and (min-aspect-ratio: 7/5) {
      .imageTablet {
        display: none !important;
      }
      .imageDesktop {
        display: block !important;
      }
    }

    .imageMobile {
      display: none !important;
    }
  `)}
`;

const ContentWrapper = styled.div`
  z-index: 10;
  position: absolute;
  width: 100%;
  top: calc(100vw * 0.68 + 24px + var(--navbar-mobile-height));
  padding: 0 5%;

  ${media.desktop(css`
    position: static;
    width: 50%;
  `)}
`;

export { ButtonWrapper, ImageWrapper, ContentWrapper };
